:root {
  /* DISC Colors */
  --disc-D: #c04e56;
  --disc-Di: #d16c4d;
  --disc-DI: #e19646;
  --disc-Id: #e5b34d;
  --disc-I: #e3c94e;
  --disc-Is: #cdc83c;
  --disc-IS: #a5c132;
  --disc-Si: #83bc30;
  --disc-S: #65b16b;
  --disc-Sc: #33a18a;
  --disc-SC: #3594a9;
  --disc-Cs: #2b82a5;
  --disc-C: #4966b1;
  --disc-Cd: #6453bb;
  --disc-CD: #9049b0;
  --disc-Dc: #a84b77;

  /* DISC light */
  --disc-D-light: #e7cacc;
  --disc-Di-light: #ebd2ca;
  --disc-DI-light: #eedcc9;
  --disc-Id-light: #f0e4c9;
  --disc-I-light: #f0e9cb;
  --disc-Is-light: #eae9c5;
  --disc-IS-light: #e0e6c3;
  --disc-Si-light: #d9e5c3;
  --disc-S-light: #d1e3d3;
  --disc-Sc-light: #c4dfd9;
  --disc-SC-light: #c3dde1;
  --disc-Cs-light: #c2d8e0;
  --disc-C-light: #c9d0e3;
  --disc-Cd-light: #d1cce6;
  --disc-CD-light: #dbc8e3;
  --disc-Dc-light: #e1c9d6;

  /* NOTE following colors are imported from pm-disc.css and may required update at some point */
  /* DISC very light */
  --disc-D-very-light: #fbeced;
  --disc-Di-very-light: #fceee9;
  --disc-DI-very-light: #fef2e8;
  --disc-Id-very-light: #fff8e9;
  --disc-I-very-light: #fcf8e6;
  --disc-Is-very-light: #fbfbe6;
  --disc-IS-very-light: #f7fbe7;
  --disc-Si-very-light: #f3f9e6;
  --disc-S-very-light: #e7f7e8;
  --disc-Sc-very-light: #eef7f4;
  --disc-SC-very-light: #e6f8f8;
  --disc-Cs-very-light: #e6f2f8;
  --disc-C-very-light: #eaeff6;
  --disc-Cd-very-light: #f0eef7;
  --disc-CD-very-light: #f4ebf5;
  --disc-Dc-very-light: #f9eaf3;

  /* DISC glass */
  --disc-D-glass: rgba(219, 60, 72, 0.15);
  --disc-Di-glass: rgba(219, 60, 72, 0.15);
  --disc-DI-glass: rgba(244, 129, 25, 0.15);
  --disc-Id-glass: rgba(255, 183, 39, 0.15);
  --disc-I-glass: rgba(229, 190, 0, 0.15);
  --disc-Is-glass: rgba(214, 214, 5, 0.15);
  --disc-IS-glass: rgba(175, 216, 10, 0.15);
  --disc-Si-glass: rgba(136, 193, 0, 0.15);
  --disc-S-glass: rgba(17, 178, 27, 0.15);
  --disc-Sc-glass: rgba(81, 180, 142, 0.15);
  --disc-SC-glass: rgba(0, 182, 188, 0.15);
  --disc-Cs-glass: rgba(0, 127, 182, 0.15);
  --disc-C-glass: rgba(47, 95, 165, 0.15);
  --disc-Cd-glass: rgba(103, 86, 178, 0.15);
  --disc-CD-glass: rgba(147, 53, 155, 0.15);
  --disc-Dc-glass: rgba(198, 46, 133, 0.15);

  --disc-empty: #6c6c6c;
}
