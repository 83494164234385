html[data-theme='clear-light'] {
  /* COLORS */

  /** Brand **/
  --color-black: #000000;
  --color-white: #ffffff;
  --color-blue-950: #001a29;
  --color-blue-900: #082c43;
  --color-blue-800: #003f5f;
  --color-blue-700: #006996;
  --color-blue-500: #0aacd1;
  --color-blue-400: #57d2ed;
  --color-blue-300: #6fe1f6;
  --color-blue-200: #a2e2f7;
  --color-blue-150: #bbf5ff;
  --color-blue-100: #ddfaff;
  --color-blue-overlay: #b4bec350;
  --color-blue-50: #e9eff0;
  --color-blue-10: #f2f5f5;
  --color-taupe-10: #f4f4f4;
  --color-taupe-20: #ededed;

  /** Neutral **/
  --color-neutral-white: #ffffff;
  --color-neutral-lightest: #eeeeee;
  --color-neutral-lighter: #cccccc;
  --color-neutral-light: #aaaaaa;
  --color-neutral: #666666;
  --color-neutral-dark: #444444;
  --color-neutral-darker: #222222;
  --color-neutral-darkest: #111111;
  --color-neutral-black: #000000;

  /** Gradient **/
  --color-gradient-start: #555555;
  --color-gradient-end: #333333;

  /** Semantic **/

  /*** Success/Error ***/
  --color-success: #027a48;
  --color-success-light: #ecfdf3;
  --color-error: #b42318;
  --color-error-light: #fef3f2;
  --color-do: #027a48;
  --color-dont: #b42318;

  /*** Development ***/
  --color-magenta: #ff00ff;

  /*** DISC ***/
  --color-disc-empty-glass: rgba(255, 255, 255, 0.1);
  --color-disc-empty: #72f5c8;
  --color-disc-line: #d7d4da;

  /*** Background ***/
  --color-bg-primary: var(--color-taupe-10);
  --color-bg-secondary: var(--color-taupe-20);
  --color-bg-tertiary: var(--color-white);
  --color-bg-alternate: var(--color-neutral-black);
  --color-bg-success: var(--color-success-light);
  --color-bg-error: var(--color-error-light);
  --color-bg-overlay: var(--color-blue-overlay);
  /*** Fill ***/
  --color-fill-primary: var(--color-blue-10);
  --color-fill-secondary: var(--color-blue-50);
  --color-fill-tertiary: var(--color-white);
  --color-fill-alternate: var(--color-neutral-black);

  /*** Border ***/
  --color-border-primary: var(--color-neutral-black);
  --color-border-secondary: var(--color-neutral-lighter);
  --color-border-tertiary: var(--color-neutral-dark);
  --color-border-alternate: var(--color-neutral-white);
  --color-border-success: var(--color-success);
  --color-border-error: var(--color-error);
  --color-border-disabled: var(--color-neutral-lighter);

  /*** Text ***/
  --color-text-primary: var(--color-neutral-black);
  --color-text-secondary: var(--color-neutral);
  --color-text-alternate: var(--color-neutral-white);
  --color-text-success: var(--color-success);
  --color-text-error: var(--color-error);
  --color-text-placeholder: var(--color-neutral);
  --color-text-disabled: var(--color-neutral-lighter);

  /** Stroke **/
  --color-stroke-primary: var(--color-neutral-black);
  --color-stroke-alternate: var(--color-neutral-white);
  --color-stroke-disabled: var(--color-neutral-lighter);

  /*** Link ***/
  --color-link-primary: var(--color-neutral-black);
  --color-link-secondary: var(--color-neutral);
  --color-link-alternate: var(--color-neutral-white);

  /*** Loading Bar ***/
  --color-loading-bar: #f8f8f8;
  --color-loading-bar-shimmer: #dfdfdf;

  /** Avatar **/
  --color-avatar-fg: #ffffff;
  --color-avatar-bg: #d1d1d1;

  /** Icon **/
  --color-icon: var(--color-neutral-black);

  /** Input **/
  --color-ring-primary: var(--color-neutral-black);

  /** Button **/

  /** Tooltip **/
  --color-bg-tooltip: var(--color-neutral-white);
  --color-tooltip-text: var(--color-neutral-black);

  /** Modal **/
  --color-bg-modal: var(--color-neutral-white);

  /* SHADOWS */
  --shadow-card: 2px 2px 6px 0px rgba(0, 0, 0, 0.04);

  /* SIZING */
  --side-nav-width: 300px;
  --side-nav-mini-width: 75px;
  --nav-height: 86px;
  --content-min-height: calc(100vh - var(--nav-height));
}

.custom-scrollbar {
  scrollbar-gutter: stable;
}
